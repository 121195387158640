<template>
    <div>
        <v-card outlined class="v-card-border-none">
            <v-card-title class="pt-2 pb-2 v-card-custom-title">
                <div class="text-left v-card-custom-title-left">
                    <template v-if="!VUETIFY_BREAKPOINT.mobile">
                        <span style="white-space: nowrap"
                            >Daily Loser Monitor<v-tooltip
                                right
                                v-if="configWarning"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                        color="red"
                                        small
                                        style="margin-left: 2px"
                                        >mdi-information-outline</v-icon
                                    >
                                </template>
                                <span
                                    >No config for Daily Loser Monitor can be
                                    found.</span
                                >
                            </v-tooltip></span
                        >
                    </template>
                </div>
                <div class="text-right v-card-custom-title-right">
                    <v-btn
                        v-show="!isSelectedDayToday"
                        icon
                        x-small
                        :loading="loading"
                        color="primary"
                        v-on:click="refreshData()"
                        style="margin-right: 2px"
                        class="alertRefresh"
                        ><v-icon>mdi-refresh</v-icon>
                    </v-btn>
                    <v-btn
                        icon
                        x-small
                        color="#d35400"
                        v-on:click="toggleDateFilter()"
                        style="margin-right: 2px"
                        class="alertCalender"
                        ><v-icon>mdi-calendar</v-icon>
                    </v-btn>
                    <template v-if="!VUETIFY_BREAKPOINT.mobile">
                        <download-excel
                            :data="filteredAlertData"
                            :fields="csvHeader"
                            style="display: inline"
                            type="csv"
                            name="Daily_Loser.csv"
                            :escapeCsv="false"
                        >
                            <v-btn
                                icon
                                x-small
                                :disabled="filteredAlertData.length === 0"
                                color="green"
                                style="margin-right: 2px"
                                class="alertDownload"
                                ><v-icon>mdi-microsoft-excel</v-icon>
                            </v-btn>
                        </download-excel>
                        <v-btn
                            icon
                            x-small
                            color="blue-grey darken-1"
                            v-on:click="getConfigForAlertAction('Daily Loser')"
                            style="margin-right: 2px"
                            v-if="showConfig"
                            class="alertConfig"
                            ><v-icon>mdi-cog</v-icon>
                        </v-btn>
                        {{ " " }}
                        <v-icon
                            small
                            style="color: grey"
                            @click="$emit('onRemove')"
                            class="alertClose"
                            >mdi-close-thick</v-icon
                        >
                    </template>
                </div>
            </v-card-title>

            <v-data-table
                v-if="isSelectedDayToday"
                style="max-width: 540px"
                dense
                item-key="name"
                :items="filteredAlertData"
                :headers="alertHeader"
                class="elevation-0 alert-datatable"
                :mobile-breakpoint="0"
                :items-per-page.sync="offset"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :height="height - 120"
                fixed-header
                :header-props="{ sortIcon: null }"
                :page.sync="currentPage"
                :footer-props="{
                    'items-per-page-text': '',
                    'items-per-page-options': [30, 50, 100, 300, 500, 1000],
                }"
            >
                <template v-slot:item="{ item }">
                    <tr @mouseover="updateRow(item)">
                        <td column="login">
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on">
                                        {{
                                            item.server_name + ":" + item.login
                                        }}
                                    </div>
                                </template>
                                <span
                                    v-html="extractAlertInfo(item)"
                                ></span> </v-tooltip
                            ><v-icon v-show="item.new == true"
                                >mdi-new-box</v-icon
                            >
                        </td>
                        <td
                            column="name"
                            align="left"
                            v-if="
                                selectedProfile.settings['dailyLoser'].name
                                    .showing
                            "
                        >
                            {{ item.name }}
                        </td>
                        <td
                            column="books"
                            v-if="
                                selectedProfile.settings['dailyLoser'].books
                                    .showing
                            "
                        >
                            {{ item.books }}
                        </td>
                        <td
                            column="closed_profit_eod"
                            align="right"
                            v-if="
                                selectedProfile.settings['dailyLoser']
                                    .closed_profit_eod.showing
                            "
                        >
                            {{
                                numberWithCommas(
                                    item.closed_profit_eod.toFixed(2)
                                )
                            }}
                        </td>
                    </tr>
                </template>
            </v-data-table>
            <v-data-table
                v-else
                style="max-width: 540px"
                dense
                item-key="name"
                :items="filteredAlertData"
                :headers="alertHeader"
                class="elevation-0 alert-datatable"
                :items-per-page.sync="offset"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :height="height - 120"
                :mobile-breakpoint="0"
                fixed-header
                :header-props="{ sortIcon: null }"
                :page.sync="currentPage"
                :server-items-length="alertDataAmount"
                :footer-props="{
                    pagination: {
                        page: currentPage,
                        itemsPerPage: offset,
                        pageStart: (currentPage - 1) * offset,
                        pageStop: currentPage * offset,
                        pageCount: Math.ceil(alertDataAmount / offset),
                        itemsLength: alertDataAmount,
                    },
                    'items-per-page-text': '',
                    'items-per-page-options': [30, 50, 100, 300, 500, 1000],
                }"
            >
                <template v-slot:item="{ item }">
                    <tr @mouseover="updateRow(item)">
                        <td column="login">
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on">
                                        {{
                                            item.server_name + ":" + item.login
                                        }}
                                    </div>
                                </template>
                                <span
                                    v-html="extractAlertInfo(item)"
                                ></span> </v-tooltip
                            ><v-icon v-show="item.new == true"
                                >mdi-new-box</v-icon
                            >
                        </td>
                        <td
                            column="name"
                            align="left"
                            v-if="
                                selectedProfile.settings['dailyLoser'].name
                                    .showing
                            "
                        >
                            {{ item.name }}
                        </td>
                        <td
                            column="books"
                            v-if="
                                selectedProfile.settings['dailyLoser'].books
                                    .showing
                            "
                        >
                            {{ item.books }}
                        </td>
                        <td
                            column="closed_profit_eod"
                            align="right"
                            v-if="
                                selectedProfile.settings['dailyLoser']
                                    .closed_profit_eod.showing
                            "
                        >
                            {{
                                numberWithCommas(
                                    item.closed_profit_eod.toFixed(2)
                                )
                            }}
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog v-model="visible" width="400" :hide-overlay="false">
            <v-card>
                <v-card-title class="text-h5">Filter Date</v-card-title>
                <v-card-text
                    >Choose date to filter history alerts data.</v-card-text
                >
                <v-container>
                    <v-row>
                        <v-col
                            lg="12"
                            md="12"
                            xs="12"
                            cols="12"
                            style="text-align: center"
                        >
                            <v-date-picker v-model="recordDate"></v-date-picker>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text v-on:click="refreshData()"
                        >Ok</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { helper } from "@components/mixins/helper";
import common from "@assets/js/common";
import deepClone from "deep-clone";
import { getConfig } from "@services/alert/config";

export default {
    mixins: [helper],
    props: {
        brokerId: null,
        broker: null,
        modules: null,
        height: 0,
        showing: false,
        showConfig: false,
    },
    data() {
        return {
            timeout: null,

            visible: false,
            sortBy: "closed_profit_eod",
            sortDesc: false,
            recordDate: common.getMT4TimeString().split(" ")[0],
            alertInterval: null,
            offset: 30,
            currentPage: 1,
            configWarning: false,
        };
    },
    computed: {
        ...mapState("DailyLoser", [
            "alertData",
            "alertHeader",
            "loading",
            "csvHeader",
            "alertDataAmount",
        ]),
        ...mapState("Alert", ["selectedProfile", "selectedPeriod", "books"]),
        selectedBooks() {
            return (
                this.books.filter(
                    (book) =>
                        !this.selectedProfile.unselectedBooks.includes(book)
                ) || []
            );
        },
        filteredAlertData() {
            if (!!this.selectedProfile.unselectedBooks) {
                if (this.selectedProfile.unselectedBooks.length === 0) {
                    return this.bookSelectionFilterData;
                } else {
                    const filtered = [];
                    this.bookSelectionFilterData.map((data) => {
                        const dataBooks = data.books.split(",");
                        if (
                            this.haveCommonItems(this.selectedBooks, dataBooks)
                        ) {
                            filtered.push(data);
                        }
                    });
                    return filtered;
                }
            }
            return this.bookSelectionFilterData;
        },
        bookSelectionFilterData() {
            let filtered = [];
            this.alertData.map((data) => {
                const dataBooks = data.books.split(",");
                if (this.haveCommonItems(this.books, dataBooks)) {
                    filtered.push(data);
                }
            });
            return filtered;
        },
        isSelectedDayToday() {
            return common.getMT4TimeString().split(" ")[0] <= this.recordDate;
        },
    },
    watch: {
        selectedProfile(nv) {
            const settings = nv.settings["dailyLoser"];
            const columnName = Object.keys(settings);
            const newHeader = deepClone(this.alertHeader);
            newHeader.map((item) => {
                if (columnName.includes(item.value)) {
                    item.align = settings[item.value].showing
                        ? ["closed_profit_eod", "closed_profit"].includes(
                              item.value
                          )
                            ? "right"
                            : "left"
                        : " d-none";
                }
            });
            this.UPDATE_ALERT_HEADER(newHeader);
        },
        showing(nv) {
            if (nv) {
                getConfig("daily-loser").then(({ data }) => {
                    if (data.length === 0) {
                        this.configWarning = true;
                    }
                });
                this.getAlertData();

                if (this.selectedPeriod > 1) {
                    this.alertInterval = setInterval(() => {
                        this.getAlertData();
                    }, this.selectedPeriod * 1000);
                }
            } else {
                clearInterval(this.alertInterval);
            }
        },
        selectedPeriod(nv) {
            if (this.showing) {
                clearInterval(this.alertInterval);
                if (nv !== 0) {
                    this.alertInterval = setInterval(() => {
                        this.getAlertData();
                    }, nv * 1000);
                }
            }
        },
        sortBy() {
            this.getAlertData();
        },
        sortDesc() {
            this.getAlertData();
        },
        offset() {
            this.getAlertData();
        },
        currentPage() {
            this.getAlertData();
        },
        recordDate(nv, ov) {
            if (nv !== ov) {
                this.UPDATE_ALERT_DATA([]);
            }
        },
    },
    methods: {
        ...mapMutations("DailyLoser", [
            "UPDATE_ALERT_HEADER",
            "UPDATE_ALERT_DATA",
            "UPDATE_SELECTED_DATE",
        ]),
        ...mapActions("Config", ["getConfigForAlertAction"]),
        haveCommonItems(arr1, arr2) {
            const set1 = new Set(arr1);
            const commonItems = arr2.filter((item) => set1.has(item));
            return commonItems.length > 0;
        },
        extractAlertInfo(data) {
            let result = "";

            result +=
                "<p class='mb-2'>Server Name: " + data.server_name + "</p>";
            return result;
        },
        ...mapActions("DailyLoser", ["getDailyLoserAction"]),
        refreshData() {
            this.visible = false;
            this.getAlertData();
        },
        getAlertData() {
            this.timeout && clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.UPDATE_SELECTED_DATE(this.recordDate);
                const selectedBooks = this.books.filter(
                    (element) =>
                        !this.selectedProfile.unselectedBooks.includes(element)
                );

                const params = {
                    date: this.recordDate,
                    offset: this.offset,
                    page: this.currentPage,
                    sort_by:
                        typeof this.sortBy === "object"
                            ? this.sortBy[0]
                            : this.sortBy,
                    sortDesc:
                        typeof this.sortDesc === "object"
                            ? this.sortDesc[0]
                            : this.sortDesc,
                    selected_book: selectedBooks.join(","),
                };
                this.getDailyLoserAction(params);
            }, 100);
        },
        toggleDateFilter() {
            this.visible = true;
        },
        updateRow(row) {
            if (row.new) {
                const index = this.alertData.indexOf(row);
                this.alertData[index].new = false;
                this.UPDATE_ALERT_DATA(this.alertData);
            }
        },
    },
    mounted() {
        if (!!this.VUETIFY_BREAKPOINT.mobile) {
            this.getAlertData();
        }
    },
    destroyed() {
        clearInterval(this.alertInterval);
    },
};
</script>
